export class PlyrConfig {

    private defaults: Plyr.Options = {
        controls: [],
        autoplay: false,
        muted: true
    }

    public options(overrides?: Plyr.Options): Plyr.Options {
        return overrides ? Object.assign(this.defaults, overrides) : this.defaults;
    }

}
